import React, { ReactElement, useState } from 'react'
import './Viewer.scss'
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const Viewer = ({ pdfFile, mdorFileToken }: any): ReactElement => {
  const [numPages, setNumPages] = useState<number>(0)
  const [zoom, setZoom] = useState<number>(1)

  const mdorFile = {
    url: pdfFile,
    httpHeaders: { 'Authorization': `Bearer ${mdorFileToken}` }
  }

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
  }

  return (
    <div className="mdor-viewer">
      <Document
        file={mdorFile}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        {[...Array(numPages).keys()].map((page) => (
          <Page pageNumber={page + 1} scale={zoom} renderTextLayer={false} />
        ))}
        <button
          type="button"
          className="mdor-viewer__zoom-btn mdor-viewer__zoom-btn--in"
          onClick={(): void => setZoom(zoom + 0.25)}
        >
          &#x2b;
        </button>
        <button
          type="button"
          className="mdor-viewer__zoom-btn mdor-viewer__zoom-btn--out"
          onClick={(): void => setZoom(zoom > 1 ? zoom - 0.25 : 1)}
          disabled={zoom === 1}
        >
          &#x2013;
        </button>
      </Document>
    </div >
  )
}

export default Viewer
