/* eslint-disable no-param-reassign */
import { hasValue } from '../../services/validations'

type FieldTypes = { [key: string]: string }

type FieldValidationTypes = {
  values: FieldTypes
  fieldName: string
  errors: FieldTypes
}

const validateCompanyCode = ({
  values,
  fieldName,
  errors,
}: FieldValidationTypes): void => {
  const fieldValue = values[fieldName]

  if (!hasValue(fieldValue)) {
    errors[fieldName] = 'Company code field is required.'
  }
}

const validateContractNo = ({
  values,
  fieldName,
  errors,
}: FieldValidationTypes): void => {
  const fieldValue = values[fieldName]

  if (!hasValue(fieldValue)) {
    errors[fieldName] = 'Contract number field is required.'
  }
}

const ContactUsFormValidation = (values: {}): {} => {
  const errors = {}

  validateCompanyCode({ values, fieldName: 'companyCode', errors })
  validateContractNo({ values, fieldName: 'contractNo', errors })

  return errors
}

export default ContactUsFormValidation
